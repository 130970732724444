var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// Lib
import * as React from "react";
// Components
import Checkboxes from "./Checkboxes";
var TaxonomySelection = /** @class */ (function (_super) {
    __extends(TaxonomySelection, _super);
    function TaxonomySelection() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            options: [],
            selectedOptions: [],
        };
        _this.render = function () {
            return (React.createElement(React.Fragment, null, !_this.state.options.length ? (React.createElement("div", { className: "loading" },
                React.createElement("svg", { className: "loading-animation loading__icon" },
                    React.createElement("use", { xlinkHref: "#icon--loading" })))) : (React.createElement(React.Fragment, null,
                React.createElement("header", { className: "taxonomy-selection__header" },
                    React.createElement("button", { type: "button", className: "link-btn", onClick: _this.toggleAllSelected }, "Select All"),
                    _this.props.introTitle && (React.createElement("h3", { className: "form__label" }, _this.props.introTitle)),
                    _this.props.introContent && (React.createElement("p", null, _this.props.introContent))),
                React.createElement("div", { className: "checkboxes checkboxes--inline" }, _this.state.options.map(_this.renderInput))))));
        };
        return _this;
    }
    return TaxonomySelection;
}(Checkboxes));
export default TaxonomySelection;
